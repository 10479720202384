import React from "react";

import "./styles/styles.css";

const TechnicalPage = () => {
  return (
    <>
      <section className="tecnical">
        <div className="container">
          <div className="technical__inner">
            <div className="technical__theme">
              <h1 className="technical__title">
                Таблицы совместимости универсальных тонеров производства группы
                компаний Булат по типам тонеров и моделям аппаратов
              </h1>
              <ul className="technical__list">
                <li className="technical__list-item">
                  <a
                    href="http://79.133.162.222/files/3.pdf"
                    className="technical__link"
                    target="_blank"
                  >
                    Как читать карты применения "Тонеров для бизнеса" БУЛАТ
                  </a>
                </li>
                <li className="technical__list-item">
                  <a
                    href="http://79.133.162.222/files/8.pdf"
                    className="technical__link"
                    target="_blank"
                  >
                    Карта применения ТБ Samsung и Xerox
                  </a>
                </li>
                <li className="technical__list-item">
                  <a
                    href="http://79.133.162.222/files/4.pdf"
                    className="technical__link"
                    target="_blank"
                  >
                    Карта применения ТБ Brother
                  </a>
                </li>
                <li className="technical__list-item">
                  <a
                    href="http://79.133.162.222/files/7.pdf"
                    className="technical__link"
                    target="_blank"
                  >
                    Карта применения ТБ Kyocera
                  </a>
                </li>
                <li className="technical__list-item">
                  <a
                    href="http://79.133.162.222/files/6.pdf"
                    className="technical__link"
                    target="_blank"
                  >
                    Карта применения ТБ HP и Canon (цвет)
                  </a>
                </li>
                <li className="technical__list-item">
                  <a
                    href="http://79.133.162.222/files/5.pdf"
                    className="technical__link"
                    target="_blank"
                  >
                    Карта применения ТБ HP и Canon 
                  </a>
                </li>
                <li className="technical__list-item">
                  <a
                    href="http://79.133.162.222/files/1.pdf"
                    className="technical__link"
                    target="_blank"
                  >
                    Комплектующие совместимость Samsung, Xerox
                  </a>
                </li>
                <li className="technical__list-item">
                  <a
                    href="http://79.133.162.222/files/2.pdf"
                    className="technical__link"
                    target="_blank"
                  >
                     Комплектующие совместимость HP, Canon
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TechnicalPage;
